.course_section{
    background-color: rgb(187, 134, 134);
    padding: 20px;
    color: white;
    border-radius:10px;
    margin: auto;
}

.contact_headings{
    font-size: 16px;
    font-weight: 800;
    color: rgb(12, 123, 192);
}

.contact_from input, textarea{
    width: 100%;
    margin-bottom: 10px;
    padding: 6px;
    border: 1px solid rgb(19, 19, 19);
}

.submit_btn{
    background-color: #0c7bc0;
    border: none;
    color: #fff;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 10px;
    text-transform: uppercase;
    width: 100px;
}

.login_style input{
    border: none;
    outline: none;
}

.input_div{
    border: 1px solid black;
    width: 90%;
    padding: 7px;
    border-radius: 20px;
    margin-bottom: 15px;
}